/* eslint-disable @typescript-eslint/no-explicit-any */
import { AutoreservaService } from '@services/autorreserva/autoreserva.service';
import * as dayjs from 'dayjs';
import { ItemUltimosRegistros } from '../models/autoreserva/modelosCompartidos';
import { lastValueFrom } from 'rxjs';
import { ETipoProducto } from '../enums/autoreserva/comun';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Ifecha, IItemDropdown } from '@destinux/destinux-interfaces';
import { constantes } from '../constants/autoreserva/trenes/constantesTrenes';
import { IDiccionarios } from '../models/autoreserva/transfer/transfer';
import { Pais } from '../models/autoreserva/compartido/pais';

export class Funciones {
  /** ejemplo params: 24.5,"es-ES","EUR", 2 */
  /** ejemplo output: '24,50' */
  public static formatearPrecioToLocale(
    precio: number,
    idioma: string = 'es-ES',
    moneda: string = 'EUR',
    decimales: number = 2
  ): string {
    precio = precio ?? 0;
    return precio.toLocaleString(idioma, {
      style: 'currency',
      currency: moneda,
      minimumFractionDigits: decimales,
      maximumFractionDigits: decimales,
    });
  }

  /** FORMATO EUROPEO */
  public static getDateTimeNoUSA(fecha: string | Date) {
    return fecha ? dayjs(fecha).format('DD-MM-YYYY HH:mm') : null;
  }
  /** FORMATO EUROPEO */
  public static getDateNoUSA(fecha: string | Date) {
    return fecha ? dayjs(fecha).format('DD-MM-YYYY') : null;
  }

  public static getDate(fecha: string | Date) {
    return fecha ? dayjs(fecha).format('YYYY-MM-DD') : null;
  }

  public static descargarBlob(blob: Blob, nombreFichero: string) {
    if (this.detectIE()) {
      const windowNavigator: any = window.navigator;
      if (windowNavigator.msSaveOrOpenBlob) {
        windowNavigator.msSaveOrOpenBlob(blob, nombreFichero);
      }
    } else {
      const dataType = blob.type;
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: dataType }));
      downloadLink.setAttribute('download', nombreFichero);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.parentNode?.removeChild(downloadLink);
    }
  }

  public static detectIE() {
    return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  }

  public static limpiarEspacios(texto:string):string{
    return texto.replace(/\s/g,'');
  }

  public static inicializarViajerosSeleccionables() {
    const ramas = JSON.parse(localStorage.getItem('info_user') || "{}")?.ramas;
    return ramas?.filter((rama: any) => {
      return rama.SoloAgenda == false;
    });
  }

  public static eliminarContenedorActivo() {
    const contenedorActivo = document.getElementsByClassName('contenedor-input--activo');
    if (contenedorActivo.length > 0) contenedorActivo[0].classList.remove('contenedor-input--activo');
  }

  public static b64toBlob(b64Data: string, contentType: string) {
    contentType = contentType || '';
    var sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {
      type: contentType
    });
    return blob;
  }

  public static getContentType(extension: string) {
    let contentType = '';
    switch (extension) {
      case 'pdf':
        contentType = 'application/pdf';
        break;

      case 'xlsx':
        contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;

      case 'docx':
        contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;

      case 'png':
        contentType = 'image/png';
        break;

      default:
        break;
    }
    return contentType;
  }

  public static dividirEnteroDecimal(numero: number) {
    const partesNumero = numero.toFixed(2).split('.');
    return { parteEntera: partesNumero[0], parteDecimal: partesNumero[1] };
  }

  public static async ultimasBusquedas(
    tipoProducto: ETipoProducto,
    _autoreservaService: AutoreservaService,
    isApartamento: boolean = false,
  ): Promise<ItemUltimosRegistros[]> {
    let ultimoRegistros: ItemUltimosRegistros[] = [];
    await lastValueFrom(
      _autoreservaService.getListaPeticionesDisponibilidad(tipoProducto)
    ).then((lista) => {
      if (tipoProducto == ETipoProducto.Hotel) {
        ultimoRegistros = lista.UltimosRegistros.filter((x) => {
          if (isApartamento && x.EsApartamento == isApartamento) {
            return x;
          } else if (
            !isApartamento &&
            (x.EsApartamento == isApartamento || x.EsApartamento == null)
          ) {
            return x;
          }
          else{
            return false;
          }
        });
      }else{
        if(tipoProducto == ETipoProducto.Aereo){
          _autoreservaService.setDatosInfoAeropuertosUltimasBusquedas(lista.Aeropuertos || []);
        }

        ultimoRegistros = lista.UltimosRegistros;
      }
    });
      ultimoRegistros = ultimoRegistros.filter(
        (registro, index, array) =>
          index ===
          array.findIndex((t) => {
            return (
              t.FechaInicio == registro.FechaInicio &&
              t.FechaFin == registro.FechaFin &&
              JSON.stringify(t.Origen) == JSON.stringify(registro.Origen) &&
              JSON.stringify(t.Destino) == JSON.stringify(registro.Destino) &&
              t.Habitaciones == registro.Habitaciones &&
              t.Clase == registro.Clase
            );
          })
      );
    return ultimoRegistros;
  }

  public static ignorarTildes(texto: string): string{
    return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  /**
   * @param fecha date class Dayjs
   * @returns boolean: true if param is today date (without hour), false otherwise
   */
  public static esHoy(fecha:dayjs.Dayjs): boolean {
    return fecha.format('YYYY-MM-DD') === dayjs(new Date()).format('YYYY-MM-DD');
  }

  /**
   * @param fecha date class Date
   * @returns boolean: true if param is today date (without hour) or greater, false otherwise
   */
  public static esHoyOFuturo(fecha:Date): boolean {
    return fecha.getTime() >= new Date().getTime();
  }

  /**
   * @param fecha date class Dayjs
   * @param formatoSalida string format for Dayjs
   * @returns yesterday full date in format YYYY-MM-DD HH:mm:ss'
   */
  public static cambiarHoyPorAyer(fecha: dayjs.Dayjs, formatoSalida:string): string{
    const esHoy = this.esHoy(fecha);
    return esHoy ? fecha.add(-1,'day').format(formatoSalida) : fecha.format(formatoSalida);
  }

  public static mesCorto(_translateService: TranslateService): { [mes: number]: string }{
    const mesCorto = {
      0: _translateService.instant('shared.mes.corto.enero').toUpperCase(),
      1: _translateService.instant('shared.mes.corto.febrero').toUpperCase(),
      2: _translateService.instant('shared.mes.corto.marzo').toUpperCase(),
      3: _translateService.instant('shared.mes.corto.abril').toUpperCase(),
      4: _translateService.instant('shared.mes.corto.mayo').toUpperCase(),
      5: _translateService.instant('shared.mes.corto.junio').toUpperCase(),
      6: _translateService.instant('shared.mes.corto.julio').toUpperCase(),
      7: _translateService.instant('shared.mes.corto.agosto').toUpperCase(),
      8: _translateService.instant('shared.mes.corto.septiembre').toUpperCase(),
      9: _translateService.instant('shared.mes.corto.octubre').toUpperCase(),
      10: _translateService.instant('shared.mes.corto.noviembre').toUpperCase(),
      11: _translateService.instant('shared.mes.corto.diciembre').toUpperCase(),
    };
    return mesCorto;
  }

  public static traducir(_translate: TranslateService,texto: string): string {
    return _translate.instant(texto);
  }

  public static checkDatesAreBeforeNow(dates: any[]) {
    const hoy = dayjs();
    return dates.every((date) => hoy.isBefore(dayjs(date)));
  }

  public static estiloCalendario(controlName: string, form: FormGroup, clase: string = ""){
    if((form.getError('fechasValidador') || form.controls[controlName].invalid) && form.controls[controlName].touched){
      return clase + " error-calendario";
    }
    return clase;
  }

  public static estiloViajero(form: FormGroup, controlName: string){
    if(form.controls[controlName].invalid && form.controls[controlName].touched){
      return "error-viajero";
    }
    return "";
  }

  public static scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  /**
   * inicializa las fechas según el formato Ifecha que es utilizado en el calendario
   * @param fecha a inicializar
   * @returns
   */
  public static inicializarIfecha(fecha: Date, _translateService: TranslateService): Ifecha {
    const dia = fecha.getDate();
    const mes = fecha.getMonth();
    const anyo = fecha.getFullYear();
    const mesCorto = this.mesCorto(_translateService);

    return {
      dia: dia.toString(),
      mes: mes.toString(),
      anyo: anyo.toString(),
      hora: fecha,
      mesCorto: mesCorto[mes],
    };
  }

  public static calcularTiempoViaje(salida: any, llegada: any): string {
    salida = new Date(salida);
    llegada = new Date(llegada);

    let diferencia = Math.floor((llegada.getTime() - salida.getTime()) / 1000);

    let horas = Math.floor(diferencia / 3600);
    let minutos = Math.floor((diferencia % 3600) / 60);

    let horasStr = horas.toString().padStart(2, '0');
    let minutosStr = minutos.toString().padStart(2, '0');

    return `${horasStr}:${minutosStr}`;
}

  public static getCiudadPorCodigo(codigo: any) {
    let ciudad = '';
    constantes.estaciones.filter((data: any) => {
      if (data.cdgoEstacion === codigo) {
        if (ciudad.includes('(TODAS)')) {
          ciudad = ciudad.split(' (TODAS)')[0];
        } else {
          ciudad = data.desgEstacion;
        }
      }
    });
    return ciudad;
  }

  public static getLogoTren(tipoTren: any) {
    switch (tipoTren) {
      case 'AVE':
        return 'AVE.svg';

      case 'ALVIA':
        return 'ALVIA.svg';

      case 'Intercity':
        return 'Intercity.svg';

      case 'REGIONAL':
      case 'MD':
        return 'Renfe_Media_Distancia.svg';

      case 'EUROMED':
        return 'EUROMED.svg';

      case 'TORRE ORO':
      case 'Talgo':
        return 'Talgo.svg';

      case 'AVLO':
        return 'Avlo.svg';

      case 'AVANT':
        return 'Avant.svg';

      default:
        return 'Desconocido.svg';
    }
  }

  public static getNombreProveedor(proveedor: any) {
    switch (proveedor) {
      case 0:
        return 'RENFE';

      default:
        return 'Proveedor desconocido';
    }
  }

  public static eliminarReferenciaVariable(variable: any) {
    const variableSinRef = JSON.parse(JSON.stringify(variable));
    return variableSinRef;
  }

  /** params: "yyyy-MM-dd HH:mm" or "yyyy-MM-ddTHH:mm" */
  public static calcularTotalDiasEntreFechas(fechaInicial: string, fechaFinal: string): number | null{
    let result = null;
    if (fechaInicial && fechaFinal) {
      const fechaIncialFormateada = dayjs(fechaInicial.replace(' ', 'T'));
      const fechaFinalnFormateada = dayjs(fechaFinal.replace(' ', 'T'));
      result = fechaFinalnFormateada.diff(fechaIncialFormateada, 'days');
    }
    return result;
  }

  public static getFormReferenciasEspecificas() {
    return new FormGroup({
      tipoProyectoSeleccionado: new FormControl<number>(1, [
        Validators.required,
      ]),
      opcionReferenciaSeleccionada: new FormControl<IItemDropdown | undefined>(undefined, [
        Validators.required,
      ]),
      refacturarCliente: new FormControl<number>(1, [Validators.required]),
      saltarFiltroFechas: new FormControl<boolean>(false, []),
      texto: new FormControl<string>("", [Validators.required])
    });
  }

   /**
   * Metodo que permite obtener el proveedor desde el diccionario de la respuesta de disponibilidad
   * @param codigo Codigo del proveedor
   * @param diccionarios Diccionario entregado en la respuesta de disponibilidad
   */
   static obtenerProveedor(codigo: number, diccionarios: IDiccionarios) {
    let proveedor = null;
    for (let i = 0; i < diccionarios.Proveedores.length; i++) {
      proveedor = diccionarios.Proveedores[i];
      if (proveedor.Codigo == codigo) {
        return proveedor;
      }
    }

    return null;
  }

  /**
   * Metodo que permite obtener el conector desde el diccionario de la respuesta de disponibilidad
   * @param codigo Codigo del conector
   * @param diccionarios Diccionario entregado en la respuesta de disponibilidad
   */
  static obtenerConector(codigo: number, diccionarios: IDiccionarios) {
    let conector = null;
    for (let i = 0; i < diccionarios.Conectores.length; i++) {
      conector = diccionarios.Conectores[i];
      if (conector.Codigo == codigo) {
        return conector;
      }
    }

    return null;
  }

  static cambiarEstiloBotonCerrarFormularioBusqueda(estiloNormal: boolean) {
    if (!estiloNormal) {
      let x = document.getElementsByClassName('swal2-close');
      while (x.length > 0) {
        x[0].className = 'xxxx';
      }
    } else {
      let x = document.getElementsByClassName('xxxx');
      while (x.length > 0) {
        x[0].className = 'swal2-close';
      }
    }
  }

  static obtenerParteEntera(tarifa: number) {
    const cadena = tarifa.toString();
    if (cadena.includes('.')) {
      return cadena.split('.')[0];
    }
    return tarifa;
  }

  static obtenerParteDecimal(tarifa: number) {
    const cadena = tarifa.toString();
    if (cadena.includes('.')) {
      return cadena.split('.')[1];
    }
    return '';
  }

   /**
  * Función para agrupar los elementos duplicados por cierta propiedad y concatenar los valores de otra propiedad, ordenándolos alfabéticamente
  * @param data array a agrupar
  * @param propGroup propiedad por la que se agrupa
  * @param propToConcat propiedad que debe concatenarse
  * @returns array agrupado
   */
   public static agruparDuplicadosPorPropiedadYConcatenarValoresOtraPropiedad<T>(data: T[], propGroup: keyof T, propToConcat: keyof T):
   Map<any,any[]> {
     const groupedMap  = new Map<any, any[]>();
     // Agrupar nombres por una propiedad y ordenarlos alfabéticamente por otra propiedad
     data.forEach((item) => {
       if (!groupedMap.has(item[propGroup])) {
           groupedMap.set(item[propGroup], []);
       }
       groupedMap.get(item[propGroup])?.push(item[propToConcat]);
   });
   console.log('groupedMAP: ', groupedMap);
   // Crear un nuevo array con los elementos agrupados y los valores de la otra propiedad concatenados


   return groupedMap;

   }

   public static getAge(date: Date) {
    const today = new Date();
    let age = today.getFullYear() - date.getFullYear();
    const m = today.getMonth() - date.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
      age--;
    }
    return age;
  }

  public static getNombrePais(codigoISO3: string, paises: Pais[]) {
    return paises.find((pais) => pais.alpha3.toUpperCase() == codigoISO3)?.name;
  }

  public static existeValorEnEnum(value:any, enumList:any): boolean {
    for (const k in enumList) if (enumList[k] == value) return true; return false;
}

}
