<div class="container">
    <div class="row g-flex--align-items-center">
        <div *ngIf="textoInicio !== null" class="col-auto texto-izquierda" [class]="claseTextoInicio">
            <span >{{textoInicio | uppercase }}</span>
        </div>
        <div class="col-auto">
            <span class="p-float-label">
                <p-inputSwitch [(ngModel)]="value" [class]="tipo" inputId="float-label" (ngModelChange)="EscribirValor()" [disabled]="disabled"></p-inputSwitch>
                <label for="float-label" style="color: black" *ngIf="tipo === ETipo.Primario">
                    <span *ngIf="!value">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span #textoSwitch class="texto-interno g-font--parrafo-xpq g-color-boton-blanco">{{texto}}</span>
                </label>
            </span>
        </div>
        <div *ngIf="textoFin !== null" class="col-auto texto-derecha" [class]="claseTextoFin">
            <span>{{ textoFin | uppercase }}</span>
        </div>
    </div>
</div>


