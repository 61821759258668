<!-- botones para abrir el calendario-->

<ng-template #botones let-texto="texto" let-fecha="fecha" let-mensaje="mensaje" let-fin="fin">
    <div class="g-flex g-flex--justify-content-end g-position-relative">
        <destinux-boton *ngIf="(mostrarBorrar && !conRango) || (mostrarBorrar && conRango && fin)"
            class="g-flex g-flex--align-self-end g-position-absolute position-top-0" [iconoStart]="Icon.CerrarModal"
            [modelo]="enums.EModeloBoton.icono" [color]="enums.EColorBoton.secundario" [talla]="enums.ETallaBoton.xp"
            (click)="hndEliminarFecha(fin)"></destinux-boton>
    </div>
    <div [class]="claseBotonFechas" class="container boton-calendario g-height-fill g-width-fill"
        (click)="openCalendar()">
        <div
            class="row g-height-fill g-flex--justify-content-center g-text-align-center g-flex--gap-xp g-flex--gap-xxp@gd g-flex--align-items-center">
            <div class="col-auto encabeza-boton">
                <div class="row">
                    <div class="col-auto">
                        {{texto | uppercase}}
                    </div>
                </div>
            </div>
            <div class="col-auto cuerpo-boton">{{fecha.dia}}</div>
            <div class="col-auto footer-boton">
                {{fecha.mesCorto}}
                <span class="row footer-boton" *ngIf="verAnyo">
                    {{fecha.anyo}}
                </span>
            </div>
            <div *ngIf="hora" class="col-auto cuerpo-boton">
                {{fecha.hora | date: "HH:mm"}}
            </div>
        </div>
    </div>
</ng-template>

<div class="g-height-fill g-width-fill container">
    <div class="row g-flex--gap-xp g-height-fill g-width-fill">
        <div class="g-height-fill g-width-fill" [ngClass]="{'col': conRango, 'col-10': !conRango}">
            <ng-container [ngTemplateOutlet]="boton || (mostrarFecha ? botones : anyadir)"
                [ngTemplateOutletContext]="{texto: textoBotonInicio, fecha:inicio, mensaje: mensajeErrorInicio, fin:false}"></ng-container>
        </div>
        <div *ngIf="conRango" class="col g-height-fill g-width-fill">
            <ng-container [ngTemplateOutlet]="(mostrarFechaFin) ? botones : anyadir"
                [ngTemplateOutletContext]="{texto: textoBotonFin, fecha:fin, mensaje: mensajeErrorFin, fin:true}"></ng-container>
        </div>

        <ng-template #anyadir let-texto="texto" let-mensaje="mensaje">
            <div class="container boton-calendario g-height-fill" [class]="claseBoton" (click)="openCalendar()">
                <div class="row g-height-fill g-flex--justify-content-center g-flex--align-items-center">
                    <div *ngIf="hndInfoError()" class="row g-flex--justify-content-center">
                        <destinux-informacion [tipo]="ETipoInformacion.error"
                            [informacion]="mensaje"></destinux-informacion>
                    </div>
                    <div class="col-auto">
                        <destinux-icono-svg [talla]="enums.ETallaIcono.md" [urlIcono]='Icon.Plus'></destinux-icono-svg>
                    </div>
                    <div
                        class="col-md-10 col g-flex g-flex--justify-content-space-around g-flex--align-items-center g-text-align-center">
                        <span class="g-color-parrafo g-font--titulo-xp">AÑADIR
                            {{texto | uppercase}}</span> <!--TODO: traducciones-->
                    </div>
                </div>

            </div>
        </ng-template>
    </div>
</div>
<!--fin botones abrir calendario-->

<!--calendario-->
<p-calendar #calendar [(ngModel)]="value" #calendario="ngModel" [numberOfMonths]="numeroMeses" [touchUI]="true"
    [selectionMode]="rango" [responsiveOptions]="responsiveCalendarOptions" [hideOnDateTimeSelect]="false"
    (ngModelChange)="EscribirValor(); hndClick();" [readonlyInput]="true" [responsive]="true" [minDate]="minDate"
    [maxDate]="maxDate" [showTime]="hora" [firstDayOfWeek]="1">
    <ng-template pTemplate="footer">
        <ng-container *ngIf="footer else footerEstandar" [ngTemplateOutlet]="footer"></ng-container>
        <!-- <div class="g-grid  g-grid--rows-1 g-grid--columns-2"> -->
        <ng-template #footerEstandar>
            <div class="g-flex g-flex--align-items-center g-flex--justify-content-space-between g-padding-gd">
                <span *ngIf="conRango" class="footer g-width-fill g-text-align-end">{{dias + ' ' + textoFooter |
                    uppercase}}</span>
                <destinux-boton texto="APLICAR" [iconoEnd]="Icon.FlechaDerecha" [color]="enums.EColorBoton.secundario"
                    class="g-width-fill g-flex g-flex--justify-content-end" (botonClick)="close()"></destinux-boton>
                <!--TODO: traducciones-->
            </div>
        </ng-template>
    </ng-template>
    <ng-template pTemplate="date" let-date>
        <span [ngClass]="{
            'normal': (inicio.dia === date.day && inicio.mes === date.month && inicio.anyo === date.year && !conRango),
            'fechaInicio': (date.day === fechaInicio?.getDate() && date.month === fechaInicio?.getMonth() &&
            date.year === fechaInicio?.getFullYear()),
            'inicio': (inicio.dia === date.day && inicio.mes === date.month && inicio.anyo === date.year),
            'fin': (fin.dia === date.day && fin.mes === date.month && fin.anyo === date.year),
            'dias': entreFechas(date) === true
            }">{{date.day}}</span>
    </ng-template>
</p-calendar>
<!--fin calendario-->

<destinux-notificacion [(mostrarNotificacion)]="mostrarToast" [posicion]="enums.EPosicionNotificacion.derecha"
    mensaje="La fecha no puede ser menor a la actual" [tipo]="enums.ETipoNotificacion.advertencia"
    titulo=""></destinux-notificacion>
