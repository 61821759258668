import { Injectable } from '@angular/core';
import { IExpresionesRegulares } from '../../models/configuraciones/configuraciones';
import { ServiceHttpService } from '@services/service-http.service';
import { environment } from '@destinux/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionesService {

  //#region variables
  public expresionesRegulares!: IExpresionesRegulares;
  hola = "hola";
  //#endregion

  //#region constructor
  constructor(private _httpService: ServiceHttpService) { }
  //#endregion

  //#region metodos
  getExpresionesRegulares(){
    return this._httpService.get(`${environment.recursos}configuraciones/expresionesregulares.json`) ;
  }
  //#endregion
}
