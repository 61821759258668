import { AfterViewInit, Component, ContentChild, ElementRef, HostBinding, HostListener, Input, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconoSvgComponent } from '../icono-svg/icono-svg.component';
import { Icon } from '../../iconos/iconos';
import { TooltipModule } from 'primeng/tooltip';
import { ETipoInformacion, TransformModeloIconoTipo } from '../../enums/informacion';
import { EnumsLib } from '../../utiles/enums';

@Component({
  selector: 'destinux-informacion',
  standalone: true,
  imports: [CommonModule, IconoSvgComponent, TooltipModule],
  templateUrl: './informacion.component.html',
  styleUrls: ['./informacion.component.scss'],
})
export class InformacionComponent implements AfterViewInit {
  //#region contentChild
  @ViewChild('default') default!: TemplateRef<any>;
  @ViewChild('tooltip') tooltip!: ElementRef;
  //#endregion

  //#region inputs
  @Input() tipo: ETipoInformacion = ETipoInformacion.normal;
  @Input({ required: true }) informacion!: string | TemplateRef<any>;
  @Input() elmentoPadre: number = 0;
   //#endregion

  @HostListener('window:scroll', [])
  windowScroll() {
    if (this.tooltip) {
      const drop = this.tooltip.nativeElement.getBoundingClientRect();
      if (drop.top < 0) {
        this.bottom = '100%';
        this.top = 'unset';
        this.grados = '180deg';
        this.bottomTool = 'unset';
      }
      else {
        this.bottom = 'unset';
        this.top = '100%';
        this.grados = '0';
        this.bottomTool = '125%';
      }
    }
  }
  @HostListener('window:resize', [])
  windowResize() {
    if (this.tooltip) {
      const ancho = this.elmentoPadre > 0 ? this.elmentoPadre : window.innerWidth;
      const drop = this.tooltip.nativeElement.getBoundingClientRect();
      console.log(ancho)
      if (drop.right >= ancho) {
        this.right = '-140%';
        this.left = 'unset';
        this.flecha = '90%';
      }
      else {
        this.right = 'unset';
        this.left = '-140%';
        this.flecha = '10%';
      }

    }
  }

  @HostBinding('style.--right') right: string = '0';
  @HostBinding('style.--left') left: string = '0';
  @HostBinding('style.--flecha') flecha: string = '0';
  @HostBinding('style.--bottom') bottom: string = '0';
  @HostBinding('style.--top') top: string = '0';
  @HostBinding('style.--grados') grados: string = '0';
  @HostBinding('style.--bottomTool') bottomTool: string = '125%';

  //#region variables
  public Icon = Icon;
  public TransformModeloIconoTipo = TransformModeloIconoTipo;
  public enums = EnumsLib;
  //#endregion

  ngAfterViewInit(): void {
    this.windowResize();
    this.windowScroll();
  }

  //#region  metodos html
  public hndInfoTooltip(): TemplateRef<any> {
    if (typeof (this.informacion) === 'string' || !this.informacion) {
      return this.default;
    }
    else {
      return this.informacion;
    }
  }
}
