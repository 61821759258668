<!--con mutiselector-->
<span *ngIf="multiple" [class]="srcImg ? 'p-float-label' : ''" [ngClass]="{'p-float-label': srcImg, '': !srcImg, 'error': hayFalloValidacion}">
    <p-multiSelect inputId="float-label-multi"
        [ngClass]="{'abajo': isAbajo === true, 'arriba': isAbajo === false, 'normal': isAbajo === null}"
        [options]="objeto" [(ngModel)]="value" name="value" [filter]="busqueda" optionLabel="valor"
        [placeholder]="placeholder" (onPanelShow)="onSelect()" (onClick)=" hndSelect()" display="chip" (ngModelChange)="EscribirValor()"
        (onPanelHide)="onClose()" [overlayVisible]="true">
        <ng-template *ngIf="opcionesCabecera" let-value pTemplate="selectedItems">
            <div (click)="hndSelect()">{{ opcionesCabecera }}</div>
        </ng-template>
    </p-multiSelect>
    <label for="float-label-multi" *ngIf="srcImg !== null"><destinux-icono-svg [talla]="enums.ETallaIcono.pq"
            [urlIcono]="srcImg"></destinux-icono-svg></label>
</span>
<!-- Fin multiselector-->

<!-- solo selector-->
<span *ngIf="!multiple" [ngClass]="{'p-float-label': true, 'conIconoLabel': srcImg, 'sinIconoLabel': !srcImg, 'error': hayFalloValidacion}">
    <p-dropdown [options]="objeto" [filter]="busqueda" optionLabel="valor" [(ngModel)]="value" name="value"
        [placeholder]="placeholder" (onClick)=" hndSelect()"
        [ngClass]="{'abajo': isAbajo === true, 'arriba': isAbajo === false, 'normal': isAbajo === null}"
        [style]="{height: height, width: ancho}" (onShow)="onSelect(); hndSelect()" (ngModelChange)="EscribirValor()"
        (onHide)="onClose()" inputId="float-label">
        <ng-template *ngIf="opcionesCabecera" let-value pTemplate="selectedItem">
            <div (click)="hndSelect()">{{ opcionesCabecera }}</div>
        </ng-template>
    </p-dropdown>
    <label for="float-label" *ngIf="srcImg !== null"><destinux-icono-svg [talla]="enums.ETallaIcono.pq"
            [urlIcono]="srcImg"></destinux-icono-svg></label>
</span>
<!-- fin selector-->
