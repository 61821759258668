import { style } from '@angular/animations';
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  TemplateRef,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EEstiloInputs, ETipoInputs } from '@destinux/destinux-enums';
import { ControlValue } from '../../utiles/ControlValue';
import { IconoSvgComponent } from '../icono-svg/icono-svg.component';
import { Icon } from '../../iconos/iconos';
import { EnumsLib } from '../../utiles/enums';
import { InformacionComponent } from '../informacion/informacion.component';

@Component({
  selector: 'destinux-inputs',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    IconoSvgComponent,
    InformacionComponent,
  ],
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputsComponent),
      multi: true,
    },
  ],
})
export class InputsComponent extends ControlValue implements OnInit {
  //#region inputs
  /** si se desea añadir una imagen dentro del inputs, esta debe indicar si la imagen debe estar a la derecha o a la izquierda del input. true: derecha, false: izquierda
   * campo opcional
   */
  @Input() imgDerecha: boolean = false;
  /** si se desea añadir una imagen dentro del inputs, se debe indicar la ruta de esta imagen
   * campo opcional
   */
  @Input() srcImg: string | null = null;
  /** el estilo que tomara el input según el EEstiloInputs
   * campo opcional
   */
  @Input() estilo: EEstiloInputs = EEstiloInputs.Predeterminado;
  /** es el type del input, para saber si será de tipo text o password, según el enum ETipoInputs
   * opcional
   */
  @Input() tipo: ETipoInputs = ETipoInputs.text;
  /** agrega el placeholder que se desea mostrar
   * opcional
   */
  @Input() placeholder: string = '';
  /** texto ubicado arriba del icono derecho */
  @Input() texto: string | null = null;
  /** para deshabilitar el input */
  @Input() disabled: boolean = false;
  @Input() mensajeError: string = '';
  @Input() iconErrorDerecha: boolean = true;
  @Input() class: string = "";
  @Input() mesajeInformacion: string | undefined | TemplateRef<any> = undefined;
  @Input() readOnly: boolean = false;
  @Input() style: string = "";
  @Input() elmentoPadre: number = 0;
  //#endregion

  //#region hostBinding
  @HostBinding('style.--paddingInput') paddingInput = '16px';
  //#endregion

  //#region declaracion de variables
  public tipoPassword: boolean = false;
  public ETipoInputs = ETipoInputs;
  public Icon = Icon;
  #paddingInput: string = '';

  public enums = EnumsLib;
  //#endregion

  //#region constructor
  constructor() {
    super();
  }
  //#endregion

  //#region OnInit
  ngOnInit(): void {
    if (this.tipo == ETipoInputs.password) {
      this.tipoPassword = true;
    }
    if (this.srcImg !== null && !this.imgDerecha) {
      this.paddingInput = '48px';
    }
    this.#paddingInput = this.paddingInput;
  }
  //#endregion

  //#region metodos html
  mostrarContrasenya(): void {
    if (this.tipo == ETipoInputs.password) {
      this.tipo = ETipoInputs.text;
    } else {
      this.tipo = ETipoInputs.password;
    }
  }

  borrar(evento: any) {
    if (evento.key == 'Delete' || evento.key == 'Backspace') {
      this.value = null;
      this.EscribirValor();
    }
  }

  hndInfoError() {
    if (
      this.estilo == EEstiloInputs.Error ||
      this.estilo == EEstiloInputs.ErrorDrop ||
      this.estilo == EEstiloInputs.ConTextoSuperIorError
    ) {
      if (!this.iconErrorDerecha) {
        this.paddingInput = '2rem';
      }
      return true;
    }
    this.paddingInput = this.#paddingInput;
    return false;
  }
  //#endregion
}
